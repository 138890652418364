import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import RadioButton from '@/components/Common/Inputs/RadioButton/RadioButton';
import CoinsIcon from '@/components/Common/Icons/CoinsIcon/';
import Modal from './../Modal';

export default {
    name: 'CreditBuyModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox,
        RadioButton,
        CoinsIcon
    },
    data() {
        return {
            selected: null,
            credits: [
                { credits: 4, price: 4, promo: false, },
                { credits: 9, price: 9, promo: false, },
                { credits: 39, price: 35, promo: true, },
                { credits: 58, price: 50, promo: true, },
                { credits: 86, price: 70, promo: true, },
                { credits: 132, price: 100, promo: true, },
            ]
        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch("utils/closeModal", this.$props.name);
        },
        async pay() {
            if (this.selected === null) {
                return;
            }

            try {
                const response = await this.$store.dispatch("payment/initiateCreditBuy", {credits: this.selected});
                if (!response.hasOwnProperty('paymentUrl')) {
                    throw new Error('err');
                }

                const paymentUrl = response.paymentUrl;
                window.location.replace(paymentUrl);
            } catch (err) {
                await this.$store.dispatch('utils/closeModal', 'creditBuy');
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('please-try-again-later')
                });
            }
        },
        select(id) {
            this.selected = id;
        }
    }
}