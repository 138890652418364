import Axios from '@/utils/axios';

export default {
	namespaced: true,
	state: {
		list: {},
		properties: [],
		settings: {},
		last_search: [],
		user_properties: [],
		propertyAppliences: [],
	},
	mutations: {
		SET_PROPERTY(state, payload) {
			if (typeof state.list[payload.id] === 'undefined') {
				state.list[payload.id] = payload;
			}
		},
		SET_PROPERTY_SETTINGS(state, payload) {
			state.settings = payload;
		},
		SET_SEARCH_PROPERTIES_RESULT(state, payload) {
			state.properties = payload;
		},
		SET_LAST_SEARCH_RESULT(state, payload) {
			state.last_search = payload;
		},
		SET_USER_PROPERTIES(state, payload) {
			state.user_properties = payload;
		},
		SET_PROPERTY_APPLIANCES(state, payload) {
			state.propertyAppliences = payload;
		},
	},
	actions: {
		async getProperties({ commit }, payload = {}) {

			let offset = 0;
			let limit = 12;
			let order = 'createdAt.DESC';

			if (typeof payload.offset !== 'undefined') {
				offset = payload.offset;
			}

			if (typeof payload.limit !== 'undefined') {
				limit = payload.limit;
			}

			if (typeof payload.order !== 'undefined') {
				order = payload.order;
			}

			let query = '?offset=' + offset + '&limit=' + limit + '&orderBy=' + order;

			if (typeof payload.params !== 'undefined') {
				const keys = Object.keys(payload.params);
				keys.forEach((key, index) => {
					query += '&' + key + '=' + payload.params[key];
				});
			}

			const response = await Axios.get('/api/property' + query);
			commit('SET_SEARCH_PROPERTIES_RESULT', response.data);

			return response.data;
		},
		async getProperty({ commit }, { id }) {
			const response = await Axios.get('/api/property/' + id);
			commit('SET_PROPERTY', response.data);
			return response.data;
		},
		async getUserProperty({ commit }, { id }) {
			const response = await Axios.get('/api/user/property/' + id);
			return response.data;
		},
		async getSettings({ commit }) {
			const response = await Axios.get('/api/property/settings');
			commit('SET_PROPERTY_SETTINGS', response.data);
		},
		async getUserProperties({ commit }, params) {
			const orderBy =  params.hasOwnProperty('orderBy') && params.orderBy !== null
				? params.orderBy : 'createdAt.DESC';
			const offset =  params.hasOwnProperty('offset') ? params.offset : 0;
			const limit =  params.hasOwnProperty('limit') ? params.limit : 10;
			const showDeleted =  params.hasOwnProperty('showDeleted') ? params.showDeleted : false;
			const showPremium =  params.hasOwnProperty('showPremium') ? params.showPremium : false;
			const showRequested =  params.hasOwnProperty('showRequested') ? params.showRequested : false;
			const active =  params.hasOwnProperty('active') ? params.active : false;

			let url = '/api/user/property?offset=' + offset + '&limit=' + limit + '&orderBy=' + orderBy;

			if (showDeleted) {
				url = url + '&deleted=1'
			}
			if (showPremium) {
				url = url + '&premium=1'
			}
			if (showRequested) {
				url = url + '&requested=1'
			}

			url = url + '&active=' + (active === true ? 1 : 0);

			const response = await Axios.get(url);
			commit('SET_USER_PROPERTIES', response.data.data);
			return response.data;
		},
		async createProperty({ commit }, property) {
			let url = '/api/property';
			if (property.hasOwnProperty('id') && property.id !== null) {
				url = '/api/user/property/' + property.id;
			}

			let {price, deposit, district_id, title, description, taxDescription, type, construction, heating, furniture, floor,
				totalFloors, bedrooms, bathrooms, areaSqm, billsMeta, locationAdvantages, propertyAdvantages, buildingYear,
				gpsCoordinates, images, status, petFriendly, appliances, plan} = property;

			const response = await Axios.post(url, {price, deposit, district_id, title, description, taxDescription, type,
				construction, heating, furniture, floor, totalFloors, bedrooms, bathrooms, area: areaSqm, billsMeta,
				locationAdvantages, propertyAdvantages, buildingYear, gpsCoordinates, images, status, petFriendly, appliances, plan
			});
			commit('SET_PROPERTY', response.data);
			return response.data;
		},
		async searchProperties({ commit }, ...params) {
			const searchParams = new URLSearchParams();
			params.forEach(param => {
				if (typeof param === 'object' && param !== null) {
					Object.entries(param).forEach(([key, value]) => {
						searchParams.append(key, value);
					});
				}
			});
			const last_search = Axios.get(`/api/property/?${searchParams}`);
			commit('SET_LAST_SEARCH_RESULT', last_search);
		},
		async getPropertyAppliences({ commit }) {
			const response = await Axios.get('/api/property/appliances');
			commit('SET_PROPERTY_APPLIANCES', response.data);
			const appliances = response.data;
			appliances.sort((a, b) => {
				return a.order > b.order;
			})
			return appliances;
		},
		async deleteProperty({ commit }, id) {
			await Axios.delete('/api/property/' + id);
		},
	},
};
