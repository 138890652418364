<template>
    <svg width="104" height="108"
         viewBox="0 0 104 108" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52 1.84066C79.6497 1.84066 102.159 24.9655 102.159 53.6106C102.159 82.2556 79.6497 105.38 52 105.38C24.3503 105.38 1.84071 82.2556 1.84071 53.6106C1.8407 24.9655 24.3503 1.84067 52 1.84066Z"
            stroke="#E54D4D" stroke-width="3.68142"/>
        <path d="M40.4961 41.8762L63.9639 65.344" stroke="#E64C4C" stroke-width="4.69357"
              stroke-linecap="round"/>
        <path d="M63.9648 41.8762L40.497 65.344" stroke="#E64C4C" stroke-width="4.69357"
              stroke-linecap="round"/>
    </svg>
</template>
<script>
export default {
    name: 'ErrorIcon',
}
</script>
<style lang="scss" scoped>
</style>