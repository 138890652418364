<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="footer-container">
    <div class="row d-flex justify-content-center cities" v-if="false">
      <router-link :to="{name: 'PropertySearchPage', query: {city_id: city.id} }"
                   href="#" class="col-6 col-md-auto me-md-5"
                   v-for="city in cities">
        <LocationIcon/>
        <a class="ms-1">{{ city.name }}</a>
      </router-link>
    </div>

      <div class="row logo-footer">
         <Logo/>
      </div>
    <div class="" v-if="showSubscribe === true">
      <SubscribeComponent @subscribe="handleSubscribe"/>
    </div>
    <div class="social-nav d-flex justify-content-center">
      <a href="https://www.facebook.com/sakvartirantite" target="_blank"><span class="me-3">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1197_11367)">
            <path
                d="M8.09236 8.71413C7.23781 8.71413 6.41562 8.71413 5.57402 8.71413C5.57402 11.1511 5.57402 13.5693 5.57402 16C4.55762 16 3.55417 16 2.53776 16C2.53776 13.5755 2.53776 11.1574 2.53776 8.71413C1.68321 8.71413 0.84808 8.71413 0 8.71413C0 7.75563 0 6.8222 0 5.8637C0.835132 5.8637 1.67026 5.8637 2.53129 5.8637C2.53129 5.78226 2.53129 5.72588 2.53129 5.66323C2.53129 4.91773 2.51187 4.17223 2.54424 3.42674C2.58956 2.41812 2.91972 1.51601 3.72896 0.833158C4.31161 0.350778 4.99784 0.106455 5.75529 0.0375433C6.57747 -0.0438975 7.39966 0.0187493 8.22184 0.0876608C8.33837 0.10019 8.4549 0.106455 8.5779 0.11272C8.5779 0.964717 8.5779 1.80418 8.5779 2.65618C8.44195 2.65618 8.31895 2.65618 8.18947 2.65618C7.69098 2.66245 7.18602 2.65618 6.68753 2.6875C6.0013 2.72509 5.61934 3.07591 5.58697 3.73997C5.5546 4.43535 5.58049 5.137 5.58049 5.85743C6.53863 5.85743 7.49676 5.85743 8.48079 5.85743C8.34484 6.8222 8.22184 7.75563 8.09236 8.71413Z"
                fill="#5E6A74"/>
          </g>
          <defs>
            <clipPath id="clip0_1197_11367">
              <rect width="8.57143" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </span></a>
      <a href="https://www.instagram.com/sakvartirantite_com/" target="_blank"><span class="me-3">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1197_11363)">
            <path
                d="M16.566 8.47478C16.566 9.35797 16.5723 10.2477 16.566 11.1308C16.5597 11.9491 16.5095 12.7609 16.2397 13.5401C15.6938 15.1052 14.6083 16.0403 13.0523 16.3975C12.1801 16.5988 11.2891 16.5794 10.4044 16.5858C9.11187 16.5988 7.81305 16.5988 6.5205 16.5858C5.71109 16.5729 4.90168 16.5794 4.10481 16.3975C2.72442 16.0858 1.6954 15.313 1.0805 13.9752C0.791873 13.3518 0.641285 12.6764 0.628736 11.9881C0.597364 10.0204 0.572266 8.03967 0.572266 6.06548C0.572266 5.26022 0.591089 4.45496 0.747952 3.66268C1.12442 1.79239 2.46089 0.461113 4.2805 0.155893C5.25305 -0.00645842 6.23187 3.56326e-05 7.21697 3.56326e-05C8.65383 3.56326e-05 10.0907 0.0130237 11.5213 0.0325059C12.3621 0.045494 13.1966 0.136411 13.9809 0.493584C15.3174 1.11052 16.1142 2.18853 16.4029 3.65619C16.5095 4.20169 16.5472 4.76667 16.5597 5.32516C16.5848 6.3772 16.566 7.42274 16.566 8.47478ZM15.1793 8.30593C15.173 8.30593 15.1605 8.30593 15.1542 8.30593C15.1542 7.87083 15.1668 7.42923 15.1542 6.99413C15.1229 6.05899 15.1166 5.11735 15.0225 4.18221C14.897 2.91587 14.1064 2.02618 12.9142 1.70797C12.2742 1.53263 11.6154 1.53263 10.9566 1.52614C9.61383 1.51315 8.27109 1.51315 6.93462 1.51964C6.11893 1.51964 5.30325 1.50016 4.50011 1.65602C3.38952 1.87032 2.61148 2.50025 2.26011 3.63021C2.0656 4.26014 2.0405 4.91603 2.0405 5.56544C2.02795 7.39027 2.02168 9.2151 2.04678 11.0399C2.05305 11.5984 2.09697 12.1634 2.20364 12.7089C2.4107 13.7609 3.0256 14.4753 4.02325 14.826C4.55658 15.0143 5.11501 15.0533 5.67344 15.0662C6.50795 15.0857 7.34246 15.0987 8.17697 15.0987C9.57619 15.0987 10.9754 15.1442 12.3683 15.0013C13.0021 14.9364 13.5856 14.7286 14.0813 14.2934C14.7966 13.6635 15.0538 12.8128 15.0915 11.8906C15.1417 10.6957 15.1542 9.50084 15.1793 8.30593Z"
                fill="#5E6A74"/>
            <path
                d="M8.57841 12.5594C6.30703 12.5529 4.45605 10.6372 4.45605 8.29281C4.45605 5.94197 6.30703 4.03271 8.57841 4.03271C10.8561 4.03271 12.6945 5.94197 12.6945 8.29281C12.6945 10.6566 10.8435 12.5659 8.57841 12.5594ZM8.58468 11.0593C10.0717 11.0593 11.2513 9.82541 11.2451 8.28632C11.2388 6.76022 10.0529 5.53934 8.57213 5.53934C7.09135 5.53934 5.91174 6.76671 5.91174 8.29931C5.91174 9.8319 7.09762 11.0593 8.58468 11.0593Z"
                fill="#5E6A74"/>
            <path
                d="M13.8176 3.86363C13.8176 4.42212 13.3909 4.85722 12.8576 4.85722C12.3243 4.85722 11.8976 4.40913 11.9039 3.85714C11.9039 3.31164 12.3305 2.87654 12.8576 2.87654C13.3972 2.87004 13.8176 3.30514 13.8176 3.86363Z"
                fill="#5E6A74"/>
          </g>
          <defs>
            <clipPath id="clip0_1197_11363">
              <rect width="16" height="16.5988" fill="white" transform="translate(0.572266)"/>
            </clipPath>
          </defs>
        </svg>
      </span></a>
      <a href="https://x.com/Sakvartirantite" target="_blank"><span>
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1197_11370)">
            <path
                d="M6.18006 11.6437C4.51021 11.4935 3.40394 10.6275 2.7847 9.03158C3.34132 9.10315 3.85619 9.1103 4.39193 8.96001C2.31158 8.41611 1.46274 6.54823 1.53232 5.2672C1.7828 5.36739 2.03327 5.4819 2.29071 5.55346C2.54814 5.62503 2.81949 5.66081 3.09084 5.68944C1.50449 4.30105 1.14269 2.65503 2.01936 0.679804C4.01621 3.09158 6.50011 4.4084 9.51975 4.61594C9.50583 4.25811 9.46409 3.9146 9.48496 3.57108C9.54758 2.31151 10.0972 1.30959 11.12 0.622551C12.5811 -0.365063 14.3971 -0.164677 15.6564 1.08057C15.733 1.1593 15.8025 1.18077 15.9139 1.15214C16.6096 0.987538 17.2706 0.737057 17.8968 0.379226C17.9386 0.350599 17.9873 0.329129 18.0708 0.28619C17.7924 1.1593 17.2776 1.80339 16.5749 2.30436C17.2567 2.23995 17.8968 2.03956 18.523 1.77477C18.5369 1.78908 18.5439 1.80339 18.5578 1.81771C18.2656 2.16838 17.9873 2.53337 17.6811 2.86257C17.4306 3.12737 17.1315 3.35638 16.8601 3.60686C16.8045 3.65696 16.7558 3.74999 16.7558 3.82872C16.8114 5.78963 16.3592 7.62173 15.4616 9.33931C14.5084 11.1499 13.1725 12.5813 11.4122 13.576C10.306 14.1987 9.12316 14.578 7.87773 14.7426C7.11935 14.8428 6.354 14.8714 5.59561 14.807C3.90489 14.671 2.33941 14.1486 0.906126 13.2254C0.89221 13.2182 0.885253 13.2039 0.857422 13.1681C2.81949 13.3685 4.57979 12.8818 6.18006 11.6437Z"
                fill="#5E6A74"/>
          </g>
          <defs>
            <clipPath id="clip0_1197_11370">
              <rect width="17.7143" height="14.8571" fill="white" transform="translate(0.857422)"/>
            </clipPath>
          </defs>
        </svg>
      </span></a>
    </div>
    <div class="site-nav row d-flex justify-content-center text-center">
      <div class="col-12 col-md-auto me-md-4">
        <router-link :to="{name: 'PrivacyPolicyPage'}"> {{ $t('privacy-policy') }}</router-link>
      </div>
      <div class="col-12 col-md-auto me-md-4">
        <router-link :to="{name: 'TermsAndConditionsPage'}"> {{ $t('terms-and-conditions') }}</router-link>
      </div>
      <div class="col-12 col-md-auto me-md-4">
        <router-link :to="{name: 'AboutUsPage'}"> {{ $t('about-us') }}</router-link>
      </div>
      <div class="col-12 col-md-auto me-md-4">
        <router-link :to="{name: 'BlogCategory'}" class="">{{ $t('blog') }}</router-link>
      </div>
      <div class="col-12 col-md-auto ">
        <router-link :to="{name: 'Contacts'}" class="">{{ $t('contact-us') }}</router-link>
      </div>
    </div>
  </div>
</template>
