import House from "@/components/Common/Icons/House.vue";
export default {
    name: 'Button',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        theme: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        svg: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        House
    },
    mounted() {
    }
}