import axios from 'axios';
export default {
	namespaced: true,
	state: {
		inquiries: [],
	},
	mutations: {
		SET_INQUIRIES(state, payload) {
			state.inquiries = payload;
		},
		ADD_INQUIRY(state, payload) {
			state.inquiries.push(payload);
		},
	},
	actions: {
		async getInquiries({ commit }, payload) {
			const url = '/api/property-view?offset=' + payload.offset + '&limit=' + payload.limit;
			const response = await axios.get(url);
			commit('SET_INQUIRIES', response.data);
			return response.data;
		},
		async addViewRequest({ commit }, requestBody) {
			const response = await axios.post('/api/property-view', requestBody);
			console.log('property view request sent.. ', requestBody);
			commit('ADD_INQUIRY', response.data);
		},
	},
};
