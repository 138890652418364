import Axios from '@/utils/axios';

export default {
  namespaced: true,
  state: {
    vendorOrders: [],
    list: {},
  },
  mutations: {
    SET_VENDOR_ORDERS(state, payload) {
      state.vendorOrders = payload;
    },
    SET_ORDER_IN_LIST(state, payload) {
      state.list[payload.id] = payload;
    },
  },
  actions: {
    async getVendorOrders({ commit }, payload) {
      const offset = payload.offset || 0;
      const limit = payload.limit || 0;
      const orderBy = payload.orderBy || 'createdAt.DESC';
      let uri = '/api/order?offset=' + offset + '&limit=' + limit + '&orderBy=' + orderBy;

      const status = payload.status || null;
      if (status !== null) {
          uri += '&status=' + status;
      }

      const response = await Axios.get(uri);
      commit('SET_VENDOR_ORDERS', response.data);
      return response.data;
    },
    async getOrder({ commit }, id) {
      const response = await Axios.get('/api/order/' + id);
      commit('SET_ORDER_IN_LIST', response.data);
      return response.data;
    },
    async updateOrderStatus({ commit }, payload) {
      const body = {}
      if (payload.status !== null) {
        body.status = payload.status;
      }
      const response = await Axios.post('/api/order/' + payload.id + '/status', body);
      return response.data;
    },
  }
};