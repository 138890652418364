<template>
    <svg
        style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px"
        height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M23 50A27 27 0 0 0 77 50A27 29.5 0 0 1 23 50" fill="#e98033" stroke="none">
            <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite"
                              keyTimes="0;1" values="0 50 51.25;360 50 51.25">
            </animateTransform>
        </path>
    </svg>
</template>
<script>
export default {
    name: 'LoadingCircle',
}
</script>
<style lang="scss" scoped>
</style>