import Axios from '@/utils/axios';

export default {
    namespaced: true,
    state: {
        posts: {}
    },
    mutations: {
        SET_POST(state, cities) {
        },
    },
    actions: {
        async getRoommatePosts({commit, state}, payload) {
            const offset = payload.hasOwnProperty('offset') ? payload.offset : 0;
            const limit = payload.hasOwnProperty('limit') ? payload.limit : 10;
            let filters = '';
            if (payload.hasOwnProperty('city') && payload.city !== null && payload.city !== '') {
                filters += '&city=' + payload.city;
            }
            if (payload.hasOwnProperty('priceFrom') && payload.priceFrom !== null && payload.priceFrom !== '') {
                filters += '&priceFrom=' + payload.priceFrom;
            }
            if (payload.hasOwnProperty('priceTo') && payload.priceTo !== null && payload.priceTo !== '') {
                filters += '&priceTo=' + payload.priceTo;
            }
            if (payload.hasOwnProperty('university') && payload.university !== null && payload.university !== '') {
                filters += '&university=' + payload.university;
            }
            const response = await Axios.get('/api/tenant-offer?offset=' + offset + '&limit=' + limit + filters);
            commit('SET_POST', response.data);
            return response.data;
        },
        async getUserRoommatePosts({commit, state}, payload) {
            const offset = payload.hasOwnProperty('offset') ? payload.offset : 0;
            const limit = payload.hasOwnProperty('limit') ? payload.limit : 10;
            const response = await Axios.get('/api/tenant-offer/user?offset=' + offset + '&limit=' + limit);
            return response.data;
        },
        async getRoommateUniversities({commit}) {
            const response = await Axios.get('/api/roommates/universities');
            return response.data;
        },
        async publishRoommatePost({commit}, payload) {
            const response = await Axios.post('/api/tenant-offer', payload);
            return response.data;
        },
        async updateRoommatePost({commit}, payload) {
            const response = await Axios.post('/api/tenant-offer/' + payload.id, payload);
            return response.data;
        },
        async deleteRoommatePost({commit}, payload) {
            const response = await Axios.delete('/api/roommates/posts/' + payload.id, payload);
            return response.data;
        },
        async getSingleRoommatePost({commit}, payload) {
            const response = await Axios.get('/api/tenant-offer/' + payload.id);
            return response.data;
        },
        async sendOfferMessage({commit}, payload) {
            const response = await Axios.post('/api/tenant-offer/' + payload.id + '/inquiry', payload);
            return response.data;
        }
    }
}