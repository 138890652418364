<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'user-type-mobile': true, 'not-logged': logged === 'no',}" v-if="$store.state.user.authenticated">
    <div class="d-flex d-md-block">
      <div class="d-flex profile-wrapper">
        <div class="profile-picture">
          <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_246_31415)">
              <rect width="52" height="50" rx="25" fill="#EEF1F3"/>
              <g clip-path="url(#clip1_246_31415)">
                <path
                    d="M25.9394 55.9526C8.86898 55.9041 -5.00076 41.9913 -4.95227 24.9033C-4.90377 7.83957 9.01447 -6.0005 26.1092 -5.95202C43.1554 -5.90354 56.9524 7.985 56.9524 25.0245C56.9524 42.0883 43.0341 55.9768 25.9394 55.9526ZM24.2178 29.0723C23.7329 29.1693 23.3934 29.2662 23.0297 29.3147C15.0522 30.5751 9.42668 34.938 6.00774 42.2095C5.64402 42.9609 5.81376 43.3971 6.37146 43.9789C16.992 55.0558 35.0081 55.0558 45.6529 43.9789C46.2106 43.3971 46.3318 42.9609 45.9924 42.2095C42.8402 35.447 37.6754 31.181 30.3768 29.5813C29.5766 29.4117 28.7522 29.2905 27.7095 29.0966C34.2079 27.0363 37.6026 22.3825 36.8024 16.9047C36.0023 11.4268 31.5407 7.42752 26.1577 7.37904C20.6534 7.30632 16.1918 11.136 15.2219 16.6865C14.3005 22.1886 17.5982 26.8181 24.2178 29.0723Z"
                    fill="#D9DEE2"/>
              </g>
            </g>
            <rect x="0.5" y="0.5" width="51" height="49" rx="24.5" stroke="#BFC6CC"/>
            <defs>
              <clipPath id="clip0_246_31415">
                <rect width="52" height="50" rx="25" fill="white"/>
              </clipPath>
              <clipPath id="clip1_246_31415">
                <rect width="52" height="52" fill="white" transform="translate(0 -1)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="ps-3">
            <div class="username">
                <div v-if="user.type === 'agency'">
                    {{ user.vendor.name !== null && user.vendor.name.length > 20 ? user.vendor.name.slice(0, 20) + "..." : user.vendor.name }}
                </div>
                <div v-else>
                    {{ user.name !== null && user.name.length > 20 ? user.name.slice(0, 20) + "..." : user.name }}
                </div>
            </div>
            <div class="user-type " v-if="$store.state.user.info.type === 'vendor'">{{ $t('vendor') }}</div>
            <div class="user-type" v-if="$store.state.user.info.type === 'agency'">{{ $t('agency') }}</div>
            <div class="user-type" v-if="$store.state.user.info.type !== 'vendor' && $store.state.user.info.type !== 'agency'">{{$t('user')}}</div>
          </div>
      </div>

      <div class="ms-auto credits not-logged">
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_242_32817)">
            <path
                d="M6.77165 3.83931C6.662 2.68754 7.3199 2.01568 8.15492 1.46598C9.22612 0.75921 10.4238 0.427642 11.6553 0.21823C14.076 -0.174417 16.4715 -0.05226 18.7994 0.785387C19.5838 1.0646 20.3092 1.45725 20.9081 2.07676C21.3804 2.56539 21.6166 3.14127 21.5322 3.83931C21.3382 4.30176 21.1021 4.72931 20.7309 5.0696C20.5454 5.23539 20.3008 5.34009 20.1827 5.58441C19.7357 5.78509 19.2971 5.98578 18.85 6.18647C18.403 6.3348 17.956 6.47441 17.5005 6.62274C17.0534 6.71 16.6064 6.79725 16.1594 6.87578C15.0291 6.89323 13.9073 7.02411 12.7687 6.89323C11.6216 6.77107 10.4997 6.57039 9.42855 6.15156C8.29831 5.70656 7.23555 5.13068 6.77165 3.83931Z"
                fill="#FCD44F"/>
            <path
                d="M0.0494242 14.964C-0.0686605 13.8122 0.58924 13.1316 1.4327 12.5819C2.57981 11.8315 3.86187 11.4999 5.17767 11.2993C5.70062 11.2207 6.232 11.1771 6.75495 11.116C7.49719 11.1248 8.23101 11.116 8.97325 11.2033C10.5421 11.3865 12.0688 11.7006 13.4267 12.5732C14.3039 13.1316 14.945 13.8558 14.8184 15.0338C14.5738 15.4002 14.4051 15.8103 14.0762 16.1157C13.8738 16.3077 13.5954 16.4211 13.4605 16.6916C13.0134 16.8923 12.5748 17.093 12.1278 17.2937C11.6808 17.442 11.2337 17.5816 10.7783 17.7299C10.3312 17.8172 9.88419 17.9045 9.43716 17.983C8.30692 17.983 7.18511 18.14 6.04644 18.0004C4.89933 17.8608 3.77753 17.6863 2.70633 17.2588C1.57609 16.8312 0.513328 16.2553 0.0494242 14.964Z"
                fill="#FCD44F"/>
            <path
                d="M6.77236 6.62279C6.74706 6.2825 6.73019 5.95093 6.91575 5.64554C7.44713 6.49191 8.21468 7.01544 9.09188 7.39064C10.9812 8.21083 12.9718 8.41152 14.9961 8.31554C15.3841 8.29809 15.8058 8.34171 16.177 8.15848C16.6324 8.0974 17.0879 8.0625 17.5181 7.89671C17.9988 7.84436 18.4627 7.74838 18.8676 7.46044C19.3652 7.34701 19.8123 7.11142 20.2087 6.77113C20.4364 6.75368 20.5798 6.59662 20.7317 6.44828C20.9678 6.2127 21.1956 5.96838 21.4233 5.72407C21.5836 6.00328 21.5583 6.30868 21.5329 6.60534C21.339 7.06779 21.1028 7.49534 20.7317 7.83564C20.5461 8.00142 20.3015 8.10613 20.1834 8.35044C19.7364 8.55113 19.2978 8.75181 18.8507 8.9525C18.4037 9.10083 17.9567 9.24044 17.5012 9.38877C17.0542 9.47603 16.6071 9.56328 16.1601 9.65054C15.0636 9.65054 13.9671 9.8076 12.8537 9.66799C11.6982 9.51966 10.5511 9.37132 9.46301 8.94377C8.32433 8.49877 7.25314 7.92289 6.77236 6.62279Z"
                fill="#FCD44E"/>
            <path
                d="M14.6756 16.7705C14.8696 17.1021 14.8274 17.4599 14.819 17.8263C14.4985 18.4284 14.1274 18.9781 13.5369 19.3358C13.4948 19.362 13.4863 19.4318 13.461 19.4842C13.014 19.6849 12.5754 19.8855 12.1284 20.0862C11.6813 20.2346 11.2343 20.3742 10.7788 20.5225C10.3234 20.4963 9.89319 20.7406 9.42928 20.697C9.29433 20.7232 9.15938 20.7668 9.01599 20.7843C8.05444 20.8628 7.10133 20.9064 6.13135 20.8017C4.9758 20.6796 3.8287 20.5138 2.74063 20.0775C1.60196 19.615 0.497021 19.0653 0.0246821 17.7478C-0.000621743 17.4162 -0.017491 17.0847 0.168071 16.7793C0.699452 17.6256 1.467 18.1492 2.3442 18.5244C4.2673 19.362 6.28317 19.5452 8.33278 19.4493C8.69547 19.4318 9.08346 19.4754 9.42928 19.2922C9.88475 19.2311 10.3402 19.1962 10.7704 19.0304C11.2512 18.9781 11.7151 18.8821 12.1199 18.5942C12.626 18.5244 13.0056 18.1753 13.4695 17.9921C13.984 17.6867 14.3551 17.2504 14.6756 16.7705Z"
                fill="#FCD44E"/>
            <path
                d="M0.0497323 17.7475C0.522071 19.065 1.61857 19.6147 2.76568 20.0772C3.85375 20.5135 4.99242 20.6792 6.1564 20.8014C7.12638 20.8974 8.07949 20.8625 9.04104 20.784C9.17599 20.7752 9.31094 20.7316 9.45433 20.6967C9.45433 21.1679 9.49651 21.6478 9.42903 22.119C6.83117 22.4331 4.30078 22.2498 1.89691 21.0719C1.83787 21.037 1.77039 21.0108 1.71135 20.9759C0.42929 20.2866 -0.186437 19.2483 0.0497323 17.7475Z"
                fill="#FBC10A"/>
            <path
                d="M9.45412 19.3008C9.1083 19.4753 8.71187 19.4404 8.35761 19.4578C6.308 19.5451 4.29213 19.3618 2.36904 18.5329C1.49183 18.149 0.724284 17.6255 0.192903 16.7878C0.00734149 16.1945 0.0157761 15.5837 0.0495146 14.9729C0.513419 16.2643 1.57618 16.8402 2.71485 17.2852C3.78605 17.704 4.91629 17.8872 6.05496 18.0268C7.19364 18.1664 8.31544 18.0094 9.44568 18.0094C9.44568 18.4282 9.44568 18.8645 9.45412 19.3008Z"
                fill="#FBC10A"/>
            <path
                d="M16.1768 8.16697C15.7972 8.3502 15.3839 8.30658 14.9959 8.32403C12.9716 8.42001 10.981 8.2106 9.09169 7.39913C8.21449 7.02393 7.44694 6.49168 6.91556 5.65403C6.73 5.0607 6.73843 4.44991 6.77217 3.83913C7.23607 5.1305 8.29884 5.70638 9.43751 6.15138C10.5087 6.57021 11.6389 6.76217 12.7776 6.89305C13.9163 7.01521 15.0381 6.89305 16.1683 6.8756C16.1768 7.30315 16.1768 7.73942 16.1768 8.16697Z"
                fill="#FBC10A"/>
            <path
                d="M6.7719 6.62248C7.25267 7.92258 8.32387 8.49846 9.47941 8.95219C10.5675 9.37974 11.7146 9.52807 12.8701 9.6764C13.9835 9.81601 15.0716 9.65023 16.1765 9.65895C16.1765 10.0952 16.1765 10.5228 16.1849 10.9591C15.6367 11.1161 15.0631 11.1248 14.498 11.1423C14.3293 11.1423 14.1269 11.055 13.9919 11.2383C12.752 10.6275 11.47 10.1563 10.112 9.96434C9.42037 9.86836 8.72873 9.79856 8.03709 9.72003C7.69971 9.26631 7.19363 8.96091 6.92372 8.43738C6.72972 7.83533 6.73816 7.23327 6.7719 6.62248Z"
                fill="#FBC10A"/>
            <path
                d="M13.9841 11.2297C14.1275 11.0464 14.3215 11.1337 14.4901 11.1337C15.0553 11.125 15.6204 11.1162 16.1771 10.9505C16.6325 10.8894 17.0796 10.8545 17.5182 10.6974C17.9989 10.6451 18.4629 10.5491 18.8677 10.2611C19.3654 10.1477 19.8124 9.91212 20.2088 9.57182C20.4366 9.55437 20.5799 9.39731 20.7318 9.24898C20.9679 9.01339 21.1957 8.76908 21.4234 8.52477C21.5837 8.80398 21.5584 9.10937 21.5331 9.40604C21.3391 9.86849 21.1029 10.296 20.7318 10.6363C20.5462 10.8021 20.3016 10.9068 20.1835 11.1511C19.7365 11.3518 19.2979 11.5525 18.8508 11.7532C18.4038 11.9015 17.9568 12.0411 17.5013 12.1895C17.0458 12.2069 16.6157 12.4338 16.1518 12.364C15.9072 12.46 15.6457 12.46 15.3927 12.4512C14.9709 11.9801 14.4733 11.6136 13.9841 11.2297Z"
                fill="#FCD44F"/>
            <path
                d="M16.1768 15.1211C16.1683 14.6848 16.1683 14.2486 16.0924 13.8123C16.1177 13.7861 16.143 13.7599 16.1683 13.7338C16.6238 13.6727 17.0708 13.6378 17.5094 13.472C17.9902 13.4196 18.4541 13.3237 18.859 13.0357C19.3566 12.9223 19.8036 12.6867 20.2001 12.3464C20.4278 12.3289 20.5712 12.1719 20.723 12.0236C20.9592 11.788 21.1869 11.5437 21.4147 11.2993C21.5749 11.5786 21.5496 11.8839 21.5243 12.1806C21.3303 12.6431 21.0941 13.0706 20.723 13.4109C20.5375 13.5767 20.2928 13.6814 20.1748 13.9257C19.7277 14.1264 19.2891 14.3271 18.8421 14.5278C18.3951 14.6761 17.948 14.8157 17.4926 14.964C17.0624 14.9728 16.6407 15.2084 16.1768 15.1211Z"
                fill="#FCD44F"/>
            <path
                d="M16.1767 17.9049C16.1767 17.4424 16.1767 16.98 16.1851 16.5262C16.6321 16.4477 17.0876 16.4303 17.5178 16.2645C17.9985 16.2121 18.4624 16.1161 18.8673 15.8282C19.365 15.7148 19.812 15.4792 20.2084 15.1389C20.4361 15.1214 20.5795 14.9644 20.7314 14.816C20.9675 14.5805 21.1953 14.3361 21.423 14.0918C21.5833 14.371 21.558 14.6764 21.5326 14.9731C21.288 15.6013 20.9169 16.1249 20.3602 16.4913C20.2759 16.5437 20.2 16.6048 20.1831 16.7182C19.7361 16.9189 19.2975 17.1196 18.8504 17.3203C18.4034 17.4686 17.9564 17.6082 17.5009 17.7565C17.0623 17.7478 16.6321 17.9398 16.1767 17.9049Z"
                fill="#FCD44F"/>
            <path
                d="M16.1768 17.9045C16.6322 17.9394 17.0624 17.7474 17.5094 17.7387C17.5094 18.1837 17.5179 18.62 17.5179 19.065C17.0624 19.2744 16.5732 19.2831 16.0924 19.3704C16.1261 18.8817 16.1514 18.3931 16.1768 17.9045Z"
                fill="#FCDF81"/>
            <path
                d="M9.42108 22.1105C9.48856 21.6394 9.44639 21.1682 9.44639 20.6883C9.91029 20.7406 10.332 20.4876 10.7959 20.5138C10.7959 20.9588 10.7959 21.3951 10.8044 21.8401C10.3489 22.032 9.89342 22.1018 9.42108 22.1105Z"
                fill="#FCDF81"/>
            <path
                d="M21.4233 8.51586C21.1956 8.76018 20.9594 9.00449 20.7316 9.24008C20.5798 9.38841 20.4364 9.54547 20.2087 9.56292C20.2003 9.16155 20.2003 8.76018 20.1918 8.35881C20.3099 8.11449 20.5545 8.00979 20.7401 7.844C21.1112 7.50371 21.3474 7.07616 21.5414 6.61371C21.6088 7.25939 21.5076 7.88763 21.4233 8.51586Z"
                fill="#FBC10A"/>
            <path
                d="M21.4233 11.2996C21.1956 11.5439 20.9594 11.7882 20.7316 12.0238C20.5798 12.1721 20.4364 12.3292 20.2087 12.3466C20.2003 11.9453 20.1918 11.5439 20.1918 11.1425C20.3099 10.8982 20.5545 10.7935 20.7401 10.6277C21.1112 10.2874 21.3474 9.85987 21.5414 9.39742C21.6088 10.0431 21.5076 10.6713 21.4233 11.2996Z"
                fill="#FBC10A"/>
            <path
                d="M21.4233 14.0741C21.1956 14.3185 20.9594 14.5628 20.7316 14.7984C20.5798 14.9467 20.4364 15.1038 20.2087 15.1212C20.2003 14.7198 20.1918 14.3185 20.1918 13.9171C20.3099 13.6728 20.5545 13.5681 20.7401 13.4023C21.1112 13.062 21.3474 12.6344 21.5414 12.172C21.6088 12.8177 21.5076 13.4459 21.4233 14.0741Z"
                fill="#FBC10A"/>
            <path
                d="M20.1918 16.7008C20.2003 16.5874 20.2846 16.5263 20.369 16.4739C20.9341 16.1075 21.2968 15.5839 21.5414 14.9557C21.7016 16.1947 21.3895 17.2331 20.3015 17.9136C20.0991 17.5297 20.2677 17.1022 20.1918 16.7008Z"
                fill="#FBC10A"/>
            <path
                d="M21.4233 5.73286C21.1956 5.97717 20.9594 6.22149 20.7316 6.45708C20.5798 6.60541 20.4364 6.76247 20.2087 6.77992C20.2003 6.37855 20.1918 5.97717 20.1918 5.5758C20.3099 5.33149 20.5545 5.22678 20.7401 5.061C21.1112 4.7207 21.3474 4.29315 21.5414 3.8307C21.6088 4.47639 21.5076 5.10462 21.4233 5.73286Z"
                fill="#FBC10A"/>
            <path
                d="M13.4692 19.4836C13.4945 19.4313 13.5029 19.3615 13.5451 19.3353C14.1355 18.9776 14.5066 18.4279 14.8272 17.8258C14.8356 18.4104 14.8946 18.995 14.6669 19.5622C14.3717 20.0159 14.0259 20.4085 13.5788 20.7052C13.3764 20.3126 13.5451 19.885 13.4692 19.4836Z"
                fill="#FBC10A"/>
            <path
                d="M20.1918 16.7007C20.2762 17.102 20.099 17.5296 20.3015 17.9222C19.8713 18.2363 19.4243 18.5243 18.8929 18.6203C18.8845 18.184 18.8676 17.739 18.8592 17.3027C19.3062 17.102 19.7448 16.9013 20.1918 16.7007Z"
                fill="#FCDF81"/>
            <path
                d="M18.8589 17.3026C18.8673 17.7388 18.8842 18.1838 18.8926 18.6201C18.4625 18.8644 17.9901 18.9691 17.5178 19.0651C17.5178 18.6201 17.5094 18.1838 17.5094 17.7388C17.9564 17.5905 18.4119 17.4422 18.8589 17.3026Z"
                fill="#FBC10A"/>
            <path
                d="M13.4691 19.4837C13.5451 19.885 13.3848 20.3126 13.5788 20.7052C13.1486 21.0193 12.7016 21.3073 12.1702 21.4033C12.1618 20.967 12.1449 20.522 12.1365 20.0857C12.5751 19.885 13.0221 19.6843 13.4691 19.4837Z"
                fill="#FCDF81"/>
            <path
                d="M12.1362 20.0771C12.1447 20.5134 12.1615 20.9584 12.17 21.3947C11.7398 21.639 11.2675 21.7437 10.7951 21.8397C10.7951 21.3947 10.7951 20.9584 10.7867 20.5134C11.2337 20.3738 11.6808 20.2255 12.1362 20.0771Z"
                fill="#FBC10A"/>
            <path
                d="M6.91604 8.42854C7.18595 8.95207 7.69203 9.25747 8.02941 9.71119C7.60768 9.71992 7.17752 9.8159 6.76422 9.64139C6.77265 9.24001 6.68831 8.81246 6.91604 8.42854Z"
                fill="#FCD44E"/>
            <path
                d="M18.8673 7.47765C18.4625 7.76559 17.9986 7.86157 17.5178 7.91392C17.5178 7.47765 17.5178 7.0501 17.5094 6.61383C17.9564 6.46549 18.4034 6.32588 18.8589 6.17755C18.8589 6.6051 18.8673 7.04137 18.8673 7.47765Z"
                fill="#FBC10A"/>
            <path
                d="M17.5093 6.61371C17.5093 7.04998 17.5093 7.47753 17.5178 7.91381C17.0876 8.07086 16.6321 8.11449 16.1767 8.17557C16.1767 7.7393 16.1767 7.31175 16.1767 6.87547C16.6153 6.78822 17.0623 6.70096 17.5093 6.61371Z"
                fill="#FCDF81"/>
            <path
                d="M18.8676 7.47781C18.8676 7.04154 18.8592 6.61399 18.8592 6.17771C19.3062 5.97703 19.7448 5.77634 20.1918 5.57565C20.2003 5.97703 20.2087 6.3784 20.2087 6.77977C19.8123 7.12006 19.3737 7.35565 18.8676 7.47781Z"
                fill="#FCDF81"/>
            <path
                d="M14.6759 16.7704C14.3553 17.2503 13.9842 17.6866 13.4866 17.992C13.4781 17.5645 13.4697 17.1282 13.4613 16.7006C13.5962 16.4301 13.8661 16.3167 14.077 16.1247C14.4059 15.8194 14.5746 15.4005 14.8192 15.0428C14.8361 15.6274 14.8699 16.212 14.6759 16.7704Z"
                fill="#FBC10A"/>
            <path
                d="M12.1454 18.6026C11.7406 18.8906 11.2767 18.9866 10.7959 19.0389C10.7959 18.6026 10.7959 18.1751 10.7959 17.7388C11.2429 17.5905 11.69 17.4509 12.1454 17.3026C12.137 17.7301 12.137 18.1664 12.1454 18.6026Z"
                fill="#FBC10A"/>
            <path
                d="M12.1449 18.6028C12.1449 18.1665 12.1365 17.739 12.1365 17.3027C12.5835 17.102 13.0221 16.9013 13.4691 16.7007C13.4776 17.1282 13.486 17.5645 13.4944 17.992C13.0305 18.1753 12.651 18.533 12.1449 18.6028Z"
                fill="#FCDF81"/>
            <path
                d="M10.7872 17.7387C10.7872 18.175 10.7872 18.6025 10.7872 19.0388C10.357 19.1959 9.90158 19.2395 9.44611 19.3006C9.44611 18.8643 9.44611 18.4367 9.44611 18.0005C9.89314 17.9132 10.3402 17.826 10.7872 17.7387Z"
                fill="#FCDF81"/>
            <path
                d="M17.5178 16.2558C17.0876 16.4216 16.6321 16.439 16.1851 16.5176C16.1851 16.0551 16.1851 15.5839 16.1767 15.1215C16.6406 15.2 17.0623 14.9644 17.5093 14.9557C17.5093 15.392 17.5178 15.8283 17.5178 16.2558Z"
                fill="#FCDF81"/>
            <path
                d="M16.177 13.7335C16.1517 13.7597 16.1264 13.7859 16.1011 13.8121C15.9324 13.3147 15.6878 12.861 15.401 12.4334C15.6625 12.4421 15.9155 12.4421 16.1601 12.3462C16.1686 12.8086 16.1686 13.2711 16.177 13.7335Z"
                fill="#FBC10A"/>
            <path
                d="M18.8668 10.2522C18.462 10.5402 17.998 10.6362 17.5173 10.6885C17.5173 10.2522 17.5173 9.82468 17.5173 9.38841C17.9643 9.24007 18.4113 9.10047 18.8668 8.95213C18.8584 9.38841 18.8668 9.82468 18.8668 10.2522Z"
                fill="#FBC10A"/>
            <path
                d="M17.5091 9.39742C17.5091 9.83369 17.5091 10.2612 17.5091 10.6975C17.0789 10.8546 16.6234 10.8982 16.168 10.9506C16.168 10.5143 16.168 10.0867 16.1595 9.65045C16.615 9.5632 17.0621 9.47594 17.5091 9.39742Z"
                fill="#FCDF81"/>
            <path
                d="M18.8676 10.2524C18.8676 9.81612 18.8592 9.38857 18.8592 8.95229C19.3062 8.75161 19.7448 8.55092 20.1918 8.35023C20.2003 8.75161 20.2003 9.15298 20.2087 9.55435C19.8123 9.90337 19.3737 10.139 18.8676 10.2524Z"
                fill="#FCDF81"/>
            <path
                d="M18.8673 13.0359C18.4625 13.3239 17.9986 13.4199 17.5178 13.4722C17.5178 13.0359 17.5178 12.6084 17.5094 12.1721C17.9564 12.0238 18.4034 11.8842 18.8589 11.7358C18.8589 12.1721 18.8673 12.6084 18.8673 13.0359Z"
                fill="#FBC10A"/>
            <path
                d="M17.5099 12.172C17.5099 12.6083 17.5099 13.0358 17.5183 13.4721C17.0881 13.6291 16.6327 13.6728 16.1772 13.7339C16.1772 13.2714 16.1688 12.809 16.1688 12.3465C16.6327 12.4163 17.0628 12.1894 17.5099 12.172Z"
                fill="#FCDF81"/>
            <path
                d="M18.8676 13.0361C18.8676 12.5998 18.8592 12.1723 18.8592 11.736C19.3062 11.5353 19.7448 11.3346 20.1918 11.1339C20.2003 11.5353 20.2003 11.9367 20.2087 12.3381C19.8123 12.6871 19.3737 12.9227 18.8676 13.0361Z"
                fill="#FCDF81"/>
            <path
                d="M17.5178 16.2556C17.5178 15.8193 17.5178 15.3917 17.5094 14.9555C17.9564 14.8071 18.4034 14.6675 18.8589 14.5192C18.8589 14.9555 18.8673 15.383 18.8673 15.8193C18.4625 16.1072 17.9986 16.2032 17.5178 16.2556Z"
                fill="#FBC10A"/>
            <path
                d="M18.8676 15.8191C18.8676 15.3828 18.8592 14.9553 18.8592 14.519C19.3062 14.3183 19.7448 14.1176 20.1918 13.9169C20.2003 14.3183 20.2003 14.7197 20.2087 15.1211C19.8123 15.4701 19.3737 15.6969 18.8676 15.8191Z"
                fill="#FCDF81"/>
          </g>
          <defs>
            <clipPath id="clip0_242_32817">
              <rect width="21.5758" height="22.25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="ps-1 username d-none d-md-inline-block">{{ $t('wallet') }}:</span>
        <span class="ps-1 username">{{ user.credits }}</span>
        <span class="ps-1 username d-none d-md-inline-block ">{{ $t('credits') }}</span>
      </div>
    </div>
  </div>
</template>
