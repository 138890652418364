<template>
    <svg width="13" height="13" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13V5L6.5 1L12 5.5V13H1Z" stroke="#FF7A00" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
  name: 'House',
}
</script>
<style lang="scss" scoped>
</style>