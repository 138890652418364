export default {
	name: 'InputField',
	props: {
		input: {
			type: String,
		},
		content: {
			type: String,
		},
		size: {
			type: String,
			default: 'medium',
		},
		theme: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		inputtype: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		maxLength: {
			type: Number,
			default: 999,
		},
		maxValue: {
			type: Number,
		},
		name: {
			type: String,
			default: null
		}
	},
	methods: {
		handleInput(e) {
			this.$emit('update:modelValue', e.target.value);
		},
	},
};
