
export default {
    name: 'RadioButton',
    props: {
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        checked: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('update:modelValue', e.target.value === "on" ? this.$props.value : null);
        },
    },
    mounted() {
    }
}