import TrustedAgenciesIcon from "@/components/Common/Icons/TrustedAgenciesIcon";

export default {
    name: 'PolicyNavMobile',
    props: {
        logged: {
            type: String,
            default: 'regular'
        }
    },
    components: {
        TrustedAgenciesIcon
    },
    mounted() {
    }
}
