import Button from '@/components/Common/Buttons/Button/Button';
import {setLang} from '@/i18n';
import LangFlagGb from '@/components/Common/Icons/LangFlagGb';
import LangFlagBg from '@/components/Common/Icons/LangFlagBg';
import ProfileIcon from '@/components/Common/Icons/ProfileIcon';
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import SearchRoommateButtonMobile
    from "@/components/Header/HeaderMobile/SearchRoommateButtonMobile/SearchRoommateButtonMobile";
import RoomsNavMobile from "@/components/Header/HeaderMobile/RoomsNavMobile/RoomsNavMobile";
import BlogContactsNavMobile from "@/components/Header/HeaderMobile/BlogContactsNavMobile/BlogContactsNavMobile";
import PolicyNavMobile from "@/components/Header/HeaderMobile/PolicyNavMobile/PolicyNavMobile";
import NotificationModal from "../../Modal/NotificationModal/NotificationModal";
import LoginModal from "../../Modal/LoginModal/LoginModal";
import SignUpModal from "../../Modal/SignUpModal/SignUpModal";
import PassChangeModal from "../../Modal/PassChangeModal/PassChangeModal";
import RoommateOfferMessageModal from "../../Modal/RoommateOfferMessageModal/RoommateOfferMessageModal";
import ForgottenPassModal from "../../Modal/ForgottenPassModal/ForgottenPassModal";
import PropertyViewRequestModal from "../../Modal/PropertyViewRequestModal/PropertyViewRequestModal";
import CreditBuyModal from "../../Modal/CreditBuyModal/CreditBuyModal";
import CoordinatesGuideModal from "../../Modal/CoordinatesGuideModal/CoordinatesGuideModal";
import TrustedAgenciesIcon from "@/components/Common/Icons/TrustedAgenciesIcon";
import Logo from "@/components/Header/Logo.vue";
import House from "@/components/Common/Icons/House.vue";

export default {
    name: 'Header',
    components: {
        House,
        Button,
        Logo,
        ProfileIcon,
        LangFlagGb,
        LangFlagBg,
        NotificationModal,
        LoginModal,
        SignUpModal,
        PassChangeModal,
        ForgottenPassModal,
        PropertyViewRequestModal,
        RoommateOfferMessageModal,
        CreditBuyModal,
        UserTypeMobile,
        UserNavMobile,
        SearchRoommateButtonMobile,
        RoomsNavMobile,
        BlogContactsNavMobile,
        PolicyNavMobile,
        CoordinatesGuideModal,
        TrustedAgenciesIcon
    },
    data() {
        return {
            isServiceMenuDropdownOpen: false,
            serviceMenuItems: [
                {
                    title: this.$t('properties-for-rent'),
                    value: 'properties-for-rent',
                    routeName: 'PropertySearchPage',
                },
                {
                    title: this.$t('interior'),
                    value: 'interior',
                    routeName: 'InteriorPage',
                },
                {
                    title: this.$t('cleaning-services'),
                    value: 'cleaning',
                    routeName: 'CleaningPage',
                },
                {
                    title: this.$t('moving-services'),
                    value: 'moving',
                    routeName: 'MovingPage',
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        modals() {
            return this.$store.state.utils.modals;
        },
        isUserAuthenticated() {
            return this.$store.state.user.authenticated;
        },
        isDevMode() {
            return window.location.hostname === 'localhost' || window.location.hostname === 'testing.sakvartirantite.intera.work';
        }
    },
    watch:{
        async $route (to, from) {
            await this.$store.dispatch('utils/closeModal', 'mobileMenu');
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    },
    methods: {
        setLanguage(lang) {
            setLang(lang);
        },
        async openCart() {
            if (Object.keys(this.$store.state.cart.products).length > 0) {
                this.$router.push({name: 'Cart'});
                return;
            }
            await this.$store.dispatch('utils/openNotificationModal', {
                type: 'error',
                message: 'Вашата количка е празна! Моля добавете продукти преди да пристъпите към плащане'
            });
        },
        toggleMobileMenu() {
            this.$store.dispatch('utils/toggleModal', 'mobileMenu');
        },
        activateTranslationsDebugMode() {
            this.setLanguage('debug');
            window.location.reload();
        },
        openLoginModal() {
            this.$store.dispatch('utils/openModal', 'login');
            this.$store.dispatch('utils/closeModal', 'mobileMenu');
        },
        redirectTo(route, params = {}, query = {}) {
            this.isServiceMenuDropdownOpen = false;
            this.$router.push({'name': route, params: params, query: query})
        }
    },
};
