<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="blog-contact-cnt">
    <router-link :to="{name: 'BlogCategory'}">
      <div class="nav-item">{{ $t('blog') }}</div>
    </router-link>
    <router-link :to="{name: 'Contacts'}">
      <div class="nav-item">{{ $t('contact-us') }}</div>
    </router-link>
  </div>
</template>
