<template>

  <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.2713 16.4291V10.7125C5.62573 10.7125 5.0012 10.7125 4.37668 10.7125C3.22149 10.7125 2.0663 10.7125 0.91111 10.7125C0.144491 10.7125 -0.121282 10.3794 0.0497602 9.6245C0.719017 6.66233 1.39178 3.70106 2.0663 0.739789C2.2005 0.147893 2.38558 0.0010378 2.97414 0.0010378C5.64941 0.000142347 8.32556 0.000142347 11.0008 0.0010378C11.5973 0.0010378 11.7859 0.146997 11.9183 0.727252C12.6042 3.73151 13.2875 6.73576 13.9691 9.74091C14.1006 10.3194 13.8059 10.6999 13.2191 10.7107C12.7981 10.7187 12.3771 10.7125 11.9078 10.7125C11.9078 11.4127 11.8981 12.0772 11.9174 12.7416C11.9201 12.8446 12.0446 12.9565 12.1332 13.0433C12.7402 13.6335 12.77 14.5244 12.1964 15.111C11.6394 15.6814 10.7412 15.6724 10.1939 15.0904C9.64477 14.5065 9.68512 13.6335 10.2605 13.0487C10.3851 12.9216 10.4851 12.712 10.4938 12.5347C10.521 11.9706 10.5009 11.4047 10.4965 10.8396C10.4965 10.8137 10.4772 10.7868 10.4579 10.7367H7.7291V16.4085C7.8598 16.4147 8.00102 16.4273 8.14136 16.4282C9.12112 16.43 10.1009 16.4255 11.0798 16.4309C11.6429 16.4335 11.9016 16.7022 11.9052 17.2824C11.9095 17.9218 11.9122 18.562 11.9043 19.2014C11.8973 19.7199 11.6262 19.9957 11.1122 19.9966C8.36327 20.001 5.61432 20.001 2.86625 19.9966C2.35663 19.9966 2.08823 19.7145 2.08297 19.1924C2.07595 18.5379 2.07507 17.8833 2.08297 17.2287C2.08911 16.7076 2.35663 16.4344 2.87151 16.4309C3.99425 16.4246 5.11786 16.4291 6.2713 16.4291ZM10.6526 1.44541H3.33464C2.74345 4.05118 2.15489 6.64621 1.56019 9.26542H12.4262C11.8315 6.64442 11.2429 4.05029 10.6526 1.44541ZM10.4859 17.8869H3.50218V18.5486H10.4851V17.8869H10.4859Z" fill="#5E6A74"/>
  </svg>



</template>
<script>
export default {
  name: 'LightsIcon',
}
</script>
<style lang="scss" scoped>
</style>