import LightsIcon from "@/components/Common/Icons/LightsIcon.vue";

export default {
    name: 'RoomsNavMobile',
    props: {
        usertype: {
            type: String,
            default: ''
        }
    },
    components: {
        LightsIcon

    },
    mounted() {
    }
}