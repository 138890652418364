import Axios from "@/utils/axios";

export default {
	namespaced: true,
	state: {
	},
	mutations: {
	},
	actions: {
		async initiateCreditBuy({ commit }, payload) {
			const response = await Axios.post('/api/credit/buy', payload);
			return response.data;
		},
		async checkCreditBuyStatus({ commit }, payload) {
			const response = await Axios.post('/api/payment/status', payload);
			return response.data;
		},
	},
};
