import Button from "@/components/Common/Buttons/Button/Button";
import LocationIcon from "@/components/Common/Icons/LocationIcon";
import SubscribeComponent from "@/components/Footer/SubscribeComponent.vue";
import Logo from "@/components/Header/Logo.vue";

export default {
    name: 'Footer',
    components: {
        Logo,
        SubscribeComponent,
        Button,
        LocationIcon
    },
    props: {
        showSubscribe: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        cities() {
            return this.$store.state.location.cities
        },
    },
    methods: {
        async handleSubscribe(email) {
            try {
                await this.$recaptchaLoaded()
                const code = await this.$recaptcha('login')
                await this.$store.dispatch("subscribe/addSubscriber", {email, code});
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'success',
                    message: this.$t('subscribing-successful-message')
                });
            } catch (e) {
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('subscribing-error-message')
                });
            }
        },
    }
}