import { createStore } from 'vuex';
import property from './property';
import location from './location';
import utils from './utils';
import propertyView from './propertyView';
import agency from './agency';
import product from './product';
import vendor from './vendor';
import cart from "./cart";
import order from "./order";
import user from "./user";
import blog from "./blog";
import roommate from "./roommate";
import favorites from "./favorites";
import cleaning from "./cleaning";
import subscribe from "./subsribe";
import payment from "./payment";

export default createStore({
	modules: {
		property,
		location,
		roommate,
		utils,
		propertyView,
		agency,
		product,
		vendor,
		cart,
		order,
		user,
		blog,
		favorites,
		cleaning,
		subscribe,
		payment,
	},
});
