import axios from 'axios';
export default {
	namespaced: true,
	state: {
		current: null,
	},
	mutations: {
		SET_VENDOR(state, payload) {
			state.current = payload;
		},
	},
	actions: {
		async addSubscriber({ commit }, payload) {
			const response = await axios.post('/api/newsletter/subscribe', payload);
			return response.data;
		},
	},
};
