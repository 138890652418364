import ModalCloseBtn from "./ModalCloseBtn";

export default {
    name: 'Modal',
    components: {
        ModalCloseBtn,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'regular',
        },
        showCloseBtn: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    mounted() {
        let vm = this;
    },
    methods: {
        closeModalIfBackdrop(event) {
            if (event.target.classList.contains('modal')) {
                this.closeModal();
            }
        },
        closeModal() {
            this.$store.dispatch("utils/closeModal", this.$props.name);
            this.$emit('onClose');
        },
    }
}