<template>
    <div class="subscribe-form-container d-flex justify-content-center">
        <div class="col-md-6">
            <div class="d-md-flex justify-content-center text-center subscribe-title">
                {{ $t('footer-subscribe-title') }}
            </div>
            <div class="d-md-flex justify-content-center text-center subscribe-subtitle">
                {{ $t('footer-subscribe-subtitle') }}
            </div>
            <div v-bind:class="{'subscribe-form-error': error}" class="subscribe-form d-flex col-md-6 container ">
                <input class="me-auto"
                       type="email"
                       v-model="email"
                       @input="handleInput()"/>
                <Button @click="send()" :theme="'solid-orange'" :size="'small'" :text="$t('send')" :svg="'send'"></Button>
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@/components/Common/Buttons/Button/Button";

export default {
    name: 'SubscribeComponent',
    components: {
        Button,
    },
    data() {
        return {
            email: null,
            error: false
        }
    },
    methods: {
        handleInput() {
            this.validateEmail()
        },
        validateEmail() {
            this.error = false;
            const mailRegex = /\S+@\S+\.\S+/;
            if (this.email === null || this.email === '' || !mailRegex.test(this.email)) {
                this.error = true;
                return;
            }
        },
        send() {
            this.validateEmail();
            if (this.error) {
                return;
            }

            this.$emit('subscribe', this.email)
            this.email = null;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.subscribe-form-container {
    padding: $spacing-4 $spacing-2;
}

.subscribe-form {
    border-radius: 32px;
    background-color: $color-grey-100;
    padding: $spacing-1;

    input {
        background-color: $color-grey-100;
        width: 100%;
        border-radius: 32px;
        border: none;

        &:focus {
            outline: none;
        }
    }
}

.subscribe-form-error {
    border: 1px solid #fd0000;
}

.paragraph {
    margin: 0;
    @include body-2;
    padding: $spacing-5 0 0;
}

.subscribe-title {
    @include title-h5;
    padding: $spacing-1 0;
}

.subscribe-subtitle {
    @include paragraph-1;
    padding: $spacing-1 0;
    margin-bottom: $spacing-2;
}
</style>