<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <Modal :name="'notification'"
           :is-open="$store.state.utils.modals.notification"
    >
      <div style="width: 420px; max-width: 100%">
        <div class="d-flex justify-center">
          <div class="m-auto">
            <div class="ml-auto">
              <svg v-if="$store.state.utils.notificationModalType ==='success'" width="116" height="120"
                   viewBox="0 0 116 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 188">
                  <path id="Ellipse 3"
                        d="M58 2.05308C88.84 2.05308 113.947 27.8462 113.947 59.7964C113.947 91.7467 88.84 117.54 58 117.54C27.16 117.54 2.0531 91.7467 2.05309 59.7964C2.05309 27.8462 27.16 2.05308 58 2.05308Z"
                        stroke="#93C034" stroke-width="4.10619"/>
                  <path id="Vector 26" d="M34.9023 57.188L51.7047 73.9115L80.5838 45.1681" stroke="#93C034"
                        stroke-width="4.10619" stroke-linecap="round"/>
                </g>
              </svg>
                <ErrorIcon v-if="$store.state.utils.notificationModalType ==='error'"/>
                <LoadingCircle v-if="$store.state.utils.notificationModalType ==='loading'"/>
            </div>
          </div>
        </div>
        <div class="text-center mt-4 mb-5 notification-message"
             v-if="$store.state.utils.notificationModalMessage"
            v-html="$t($store.state.utils.notificationModalMessage)">
        </div>
      </div>
    </Modal>
  </div>
</template>